/**	Write your changes below	**/
header .container a.logo img {
  width: 15rem;
}

@media (min-width: 55em) {
  .container {
    max-width: 96rem;
  }
  nav#navbar > ul > li.has-submenu .submenu {
    visibility: collapse;
  }
  nav#navbar > ul > li.has-submenu.active .submenu {
    visibility: visible;
  }

  .lightbox img {
    width: auto;
  }
}
@media (max-width: 55em) {
  #navbar > ul {
    overflow-y: scroll;
    height: calc(100% - 8rem);
  }
}

.fixed {
  position: fixed;
}

.page-title {
  padding-inline: 1rem;
}

.gallery-container {
  gap: 3rem;
}

.border {
  border: .1rem solid #ddd;
}
.txt-dark {
  color: #000;
}
.visible {
  display: block !important;
}

footer img {
  padding-top: 6rem;
  width: 22rem;
}

button#goTop svg, .btn#goTop svg {
  margin-inline: auto;
}

table {
  width: 100%;
  border-top: .1rem solid #ddd;
  padding-block: 1.5rem;
}
table:last-child {
  border-bottom: .1rem solid #ddd;
}
table tr {
  line-height: 2rem;
  vertical-align: top;
}
table h4 {
  margin: 0;
}
table tr td {
  width: 50%;
}
table tr td:last-child {
  font-weight: 800;
}

.social-share {
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 1rem;
}
.social-share li svg {
	width: 1.5rem;
	height: 1.5rem;
	transition: var(--ease-in-out);
}
.social-share li:hover svg {
	opacity: .5;
}

.fs-lg {
  line-height: 1;
}

/* carousel thumb */
.carousel-thumb {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.carousel-thumb-img {
	position: relative;
	width: 100%;
	margin-bottom: .5rem;
}
.carousel-thumb-img button {
	position: absolute;
	top: 50%;
	left: 0;
	padding-inline: 1rem;
	transform: translateY(-50%);
  opacity: .5;
}
.carousel-thumb-img button:hover {
  opacity: 1;
}
.carousel-thumb-img button:last-child {
	position: absolute;
	left: unset;
	right: 0;
}
.carousel-thumb-thumbnails {
	display: flex;
	flex-wrap: wrap;
	gap: .5rem;
	margin-bottom: 2rem;
}


.carousel-thumb-thumbnail {
  width: calc(100% / 7);
  cursor: pointer;
  border: .2rem solid transparent;
  transition: all .3s ease-in-out;
}

.carousel-thumb-thumbnail:hover,
.carousel-thumb-thumbnail.active {
	border: .2rem solid #009ee2;
}

button#goTop,
.btn#goTop {
  opacity: .5;
}
button#goTop:hover,
.btn#goTop:hover {
  opacity: 1;
}

@media (min-width: 55em) {
  table tr td {
    width: 33.333%;
  }
  .carousel-thumb-thumbnail {
    width: 6rem;
  }
  .mb-screen--7 {
    margin-bottom: -8rem;
  }

  .bg-zoom {
    animation-duration: 9s;
  }
}

/* what app chat */
a#whatsapp-chat {
	display: block;
	position: fixed;
	right: 1.8rem;
	bottom: 8rem;
	height: 4rem;
	width: 4rem;
	border-radius:100%;
	background: #29c562 url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M3.516 3.516c4.686-4.686 12.284-4.686 16.97 0 4.686 4.686 4.686 12.283 0 16.97a12.004 12.004 0 01-13.754 2.299l-5.814.735a.392.392 0 01-.438-.44l.748-5.788A12.002 12.002 0 013.517 3.517zm3.61 17.043l.3.158a9.846 9.846 0 0011.534-1.758c3.843-3.843 3.843-10.074 0-13.918-3.843-3.843-10.075-3.843-13.918 0a9.846 9.846 0 00-1.747 11.554l.16.303-.51 3.942a.196.196 0 00.219.22l3.961-.501zm6.534-7.003l-.933 1.164a9.843 9.843 0 01-3.497-3.495l1.166-.933a.792.792 0 00.23-.94L9.561 6.96a.793.793 0 00-.924-.445 1291.6 1291.6 0 00-2.023.524.797.797 0 00-.588.88 11.754 11.754 0 0010.005 10.005.797.797 0 00.88-.587l.525-2.023a.793.793 0 00-.445-.923L14.6 13.327a.792.792 0 00-.94.23z'/%3E%3C/svg%3E") 50% no-repeat;
	background-size: 65%;
	z-index: 9996;
	animation: fade ease-in 3s;
}
a#whatsapp-chat > p {
	position: fixed;
	bottom: 8.7rem;
	right: 7rem;
	width: max-content;
	padding: .35rem 1rem;
	border-radius: 1.5rem;
	color: #000;
	background-color: #fff;
	transform: scale(0);
	transform-origin: right;
	transition: .8s ease;
}
a#whatsapp-chat:hover > p {
	transform: scale(1);
}