/*--------------------------------------------------------*/
/*                                                        */
/*       1.  CSS RESET                                    */
/*       2.  FONTS                                        */
/*       3.  UTILITIES                                    */
/*       4.  BUTTONS                                      */
/*       5.  HEADER AND NAVIVATION                        */
/*       6.  SECTIONS                                     */
/*       7.  SERVICES AND PARTNERS                        */
/*       8.  PRICINGS                                     */
/*       9.  TESTIMONIALS                                 */
/*       10. GALLERY                                      */
/*       11. ACCORDION                                    */
/*       12. BLOG                                         */
/*       13. FOOTER                                       */
/*       14. ANIMATIONS                                   */
/*                                                        */
/*--------------------------------------------------------*/
/*--------------------------------------------------------*/
/*	1. CSS RESET */
/*--------------------------------------------------------*/
* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  list-style: none;
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
}

body {
  position: relative;
  background: #ffffff;
  overflow-x: hidden;
}

img {
  width: 100%;
}

ul.inline-list li {
  display: inline-block;
  padding-right: 0.5rem;
}

aside {
  width: 100%;
  padding: 2rem 1.4rem;
}

iframe {
  width: 100%;
  min-height: 18rem;
}

hr {
  width: 100%;
  margin-block: 4.5rem;
  border-top: 0.1rem solid rgba(255, 255, 255, 0.2);
}

form > *:not(:last-child) {
  margin-bottom: 1.4rem;
}

label {
  position: absolute;
  display: block;
}
label svg {
  padding-left: 1.25rem;
  padding-top: 1.6rem;
}

input,
textarea,
select {
  width: 100%;
  padding: 1.4rem 0.6rem 1.4rem 3rem;
  background: #ffffff;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
}

[hidden] {
  display: none !important;
  transition: 1s ease;
}

#preload {
  position: fixed;
  inset: 0;
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-content: center;
  background: #ffffff;
  z-index: 99999;
}
#preload .loader {
  display: block;
}
#preload .loader::after {
  content: "";
  display: block;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  border: 0.3rem solid #009ee2;
  border-color: #009ee2 transparent;
  -webkit-animation: loader 1.2s linear infinite;
          animation: loader 1.2s linear infinite;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
}

.skip-to-content {
  position: absolute;
  z-index: 9999;
  background: #ffffff;
  padding: 0.5em 1em;
  margin-inline: auto;
  transform: translateY(-100%);
  transition: transform 250ms ease-in;
}
.skip-to-content:focus {
  transform: translateY(0);
}

.yt-popup-btn {
  position: relative;
  display: inline-block;
  aspect-ratio: 1;
  border-radius: 100%;
  border: 0.2rem solid #ffffff;
  background: transparent;
  transition: 0.4s ease;
}
.yt-popup-btn svg {
  transform: scale(2);
  color: #ffffff;
  transition: 0.4s ease;
}
.yt-popup-btn:before {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: #ffffff;
  transform: scale(0);
  transition: 0.3s ease;
}
.yt-popup-btn:hover {
  border: 0.2rem solid #009ee2;
  background: transparent;
}
.yt-popup-btn:hover svg {
  color: #009ee2;
}
.yt-popup-btn:hover:before {
  transform: scale(1);
}

.yt-popup {
  display: none;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 9999;
}
.yt-popup .yt-popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 95%;
  aspect-ratio: 3/2;
  transform: translate(-50%, -50%);
}
.yt-popup .yt-popup-content .close-yt-popup {
  float: right;
  color: #ffffff;
  cursor: pointer;
}
.yt-popup .yt-popup-content iframe {
  width: 100%;
  height: 100%;
}
@media (min-width: 55em) {
  .yt-popup .yt-popup-content {
    width: 42rem;
  }
}

/*--------------------------------------------------------*/
/*	2. FONTS */
/*--------------------------------------------------------*/
body {
  font-family: "Mulish", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #192026;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Taviraj", serif;
  font-weight: 500;
  line-height: 1.4;
  color: #192026;
  margin-bottom: 1rem;
}

h1 {
  font-size: calc(1.4rem * 1.8);
}

h2 {
  font-size: calc(1.4rem * 1.618);
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.15rem;
  color: #009ee2;
  font-style: italic;
}

h5 {
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 300;
}

h6 {
  font-size: 1.23rem;
}

a {
  font-family: "Taviraj", serif;
  font-weight: 500;
  text-decoration: none;
  color: #192026;
  transition: all 0.3s ease;
}
a:hover {
  color: #009ee2;
}

p:not(:last-child) {
  margin-bottom: 1.4rem;
}

i {
  color: #009ee2;
}

input,
textarea,
select {
  font-family: "Mulish", sans-serif;
  font-size: 1rem;
}

button,
.btn {
  font-family: "Taviraj", serif;
  font-weight: 500;
  font-size: 1rem;
  color: #192026;
}
button svg,
.btn svg {
  color: #192026;
}
button:hover,
.btn:hover {
  color: #ffffff;
}
button:hover svg,
.btn:hover svg {
  color: #ffffff;
}

header a {
  color: #ffffff;
}
header nav a {
  font-family: "Mulish", sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
}
@media (min-width: 950px) {
  header nav a {
    font-size: 0.9rem;
  }
}
header nav .submenu a {
  text-transform: none;
}

.page-title {
  text-align: center;
}
.page-title h1,
.page-title ul {
  color: #ffffff;
}
.page-title h1 a,
.page-title ul a {
  color: #009ee2;
}
.page-title h1 a:hover,
.page-title ul a:hover {
  color: #ffffff;
}

.service {
  font-family: "Taviraj", serif;
  text-align: center;
}
.service svg {
  font-size: 3rem;
}

.service-cta {
  text-align: center;
  color: #f5f5f5;
}
.service-cta a {
  font-size: 1.4rem;
  color: #009ee2;
}
.service-cta a:hover {
  color: #ffffff;
}
.service-cta a svg {
  font-size: 0.85rem;
}

.thumb-description {
  text-align: center;
}
.thumb-description a h3 {
  color: #ffffff;
  margin: 0;
}
.thumb-description a h3:hover {
  color: #d7b65d;
}

.post a .date-img {
  text-align: center;
}
.post a .date-img span {
  font-size: 2rem;
}
.post a:hover .date-img {
  color: #192026;
}
.post ul {
  font-size: 0.8rem;
}

.pricing-feature {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 600;
}

.big-pricing {
  color: #ffffff;
}
.big-pricing > a {
  line-height: 0;
}
.big-pricing .label {
  text-align: center;
  text-transform: uppercase;
}
.big-pricing a,
.big-pricing .label,
.big-pricing svg {
  color: #ffffff;
}
.big-pricing a.btn, .big-pricing a.btn svg,
.big-pricing .label.btn,
.big-pricing .label.btn svg {
  color: #192026;
}

.fs-xs {
  font-size: 1.4rem;
}

.fs-md,
.fs-lg {
  font-size: 2.8rem;
}

.fs-lg {
  line-height: 0.9;
  margin-bottom: 0;
}

.txt-primary {
  color: #009ee2;
}

.txt-white {
  color: #ffffff;
}
.txt-success { color: rgb(0, 179, 0); }
.txt-danger { color: rgb(226, 0, 0); }
.txt-light {
  color: #f5f5f5;
}

.txt-grey {
  color: rgba(0, 0, 0, 0.2);
}

.txt-center {
  text-align: center;
}

.sm-txt-center {
  text-align: center;
}

footer {
  font-family: "Taviraj", serif;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  color: #ffffff;
}
footer h2,
footer a {
  color: #ffffff;
}
footer .copyright {
  font-family: "Mulish", sans-serif;
}

@media (min-width: 55em) {
  h1 {
    font-size: calc(1.4rem * 2.618);
  }
  .submenu a {
    color: #192026;
  }
  .service-cta {
    text-align: left;
  }
  .fs-md {
    font-size: 4.5rem;
  }
  .fs-lg {
    font-size: 6rem;
  }
  .sm-txt-center {
    text-align: left;
  }
}
/*--------------------------------------------------------*/
/*	3. UTILITIES */
/*--------------------------------------------------------*/
.container {
  max-width: 80rem;
  padding-inline: 1.4rem;
}

.md-screen {
  display: none;
}

.relative {
  position: relative;
}

.w-100 {
  width: 100%;
}

.mx-w-25 {
  max-width: 25rem;
}

.block {
  display: block;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
}

.flex {
  display: flex;
}

.flex,
.grid {
  gap: 1.8rem;
}

.place-center {
  place-items: center;
}

.align-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.g-1 {
  gap: 1rem;
}

.g-sm {
  gap: 3rem;
}

.g-lg {
  gap: 6rem;
}

.p-0 {
  padding: 0 !important;
}

.p-2 {
  padding: 2rem;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-2 {
  padding-bottom: 2rem;
}

.p-bk-md {
  padding-block: calc(4.5rem / 1.618);
}

.m-0 {
  margin: 0 !important;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb--2 {
  margin-bottom: -2rem;
}

.m-in-auto {
  margin-inline: auto;
}

.bg-light {
  background: #f5f5f5;
}

.bg-dark {
  background: #192026;
  color: #ffffff;
}

.bg-primary {
  background: #009ee2;
}

.bg-img {
  background-image: var(--imgUrl);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-img-left {
  background-position: center left;
  background-size: unset;
}

.parallax {
  background-attachment: fixed;
}

.overlay {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0.75;
}

.overlay-dark {
  background-color: #192026;
}

@media (min-width: 55em) {
  .bg-zoom {
    -webkit-animation: zoom 6s ease-in-out;
            animation: zoom 6s ease-in-out;
    transition: all 0.4s;
  }
}
.translateY {
  transform: translateY(5rem);
}

.scale-2 {
  transform: scale(2);
}

@media (min-width: 55em) {
  .container {
    margin-inline: auto;
  }
  .md-screen {
    display: block;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .gtc-1-2 {
    grid-template-columns: 1fr 2fr;
  }
  .gtc-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .gtc-4 {
    grid-template-columns: repeat(4, 1fr);
  }
  .mt-screen-3 {
    margin-top: 3rem;
  }
  .mb-screen--7 {
    margin-bottom: -7rem;
  }
  .pb-screen-0 {
    padding-bottom: 0;
  }
  .p-bk-md {
    padding-block: 4.5rem;
  }
}
/*--------------------------------------------------------*/
/*	4. BUTTONS */
/*--------------------------------------------------------*/
button,
.btn {
  display: inline-block;
  padding: 0.8rem 2.5rem;
  text-align: center;
  background: #009ee2;
  transition: all 0.5s ease;
  cursor: pointer;
}
button svg,
.btn svg {
  transition: all 0.5s ease;
}
button:hover,
.btn:hover {
  background-color: #192026;
}
button#goTop,
.btn#goTop {
  display: none;
  position: fixed;
  right: 1.4rem;
  bottom: 1.4rem;
  padding: 1rem;
  aspect-ratio: 1;
  z-index: 98;
  -webkit-animation: fade ease 4s;
          animation: fade ease 4s;
}
button#goTop svg,
.btn#goTop svg {
  display: block;
  font-size: 1.4rem;
  color: #192026;
}
button#goTop:hover svg,
.btn#goTop:hover svg {
  color: #ffffff;
}

form button {
  width: 100%;
  padding-block: 1.4rem;
}

/*--------------------------------------------------------*/
/*	5. HEADER AND NAVIVATION */
/*--------------------------------------------------------*/
header {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 1.4rem;
  padding-bottom: 0;
  color: #ffffff;
  z-index: 99;
}
header.fixed {
  position: fixed;
  transition: 0.4s linear;
}
header.fixed .container {
  border: none;
}
header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0;
  padding-bottom: 1.4rem;
  border-bottom: 0.1rem dashed rgba(255, 255, 255, 0.2);
  border-width: 0.15rem;
}
header .container a.logo {
  line-height: 0;
  z-index: 999;
}
header .container a.logo img {
  width: 10rem;
}
@media (max-width: 950px) {
  header .container > div {
    display: none;
  }
}

.sm-nav-toggle {
  display: block;
  width: 2rem;
  padding: 0;
  background-color: transparent;
  z-index: 999;
}
.sm-nav-toggle .bar1,
.sm-nav-toggle .bar2,
.sm-nav-toggle .bar3 {
  width: 100%;
  height: 0.15rem;
  margin-block: 0.65rem;
  background-color: #ffffff;
  transition: all 0.8s ease;
}
.sm-nav-toggle[aria-expanded=true] .bar1 {
  width: 50%;
}
.sm-nav-toggle:hover {
  background-color: transparent;
}
@media (min-width: 950px) {
  .sm-nav-toggle {
    display: none;
  }
}

nav#navbar {
  position: fixed;
  inset: 0;
  display: block;
  width: 100%;
  padding: 1.4rem;
  background-color: #192026;
  transform: translateX(100%);
  transition: all 0.6s ease;
  z-index: 998;
}
nav#navbar[data-visible=true] {
  transform: translateX(0);
}
nav#navbar > ul {
  margin-top: 8rem;
}
nav#navbar > ul li {
  display: block;
  padding-top: 1rem;
}
nav#navbar > ul li.has-submenu > a {
  position: relative;
  display: block;
}
nav#navbar > ul li.has-submenu > a:before, nav#navbar > ul li.has-submenu > a:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0.6rem;
  display: inline-block;
  width: 1.3rem;
  height: 0.15rem;
  background-color: #ffffff;
  transition: all 0.8s ease;
}
nav#navbar > ul li.has-submenu > a:after {
  transform: rotate(-90deg);
}
nav#navbar > ul li.has-submenu .submenu {
  display: none;
  margin: 0 0 0.3rem 0.6rem;
}
nav#navbar > ul li.has-submenu .submenu > li:before {
  content: "- ";
  color: #009ee2;
}
nav#navbar > ul li.has-submenu.active .submenu {
  display: block;
}
nav#navbar > ul li.has-submenu.active > a:after {
  transform: rotate(0);
}
@media (min-width: 950px) {
  nav#navbar {
    position: relative;
    width: auto;
    padding: 0;
    background-color: transparent;
    transform: translateX(0);
  }
  nav#navbar > ul {
    margin-top: 0;
  }
  nav#navbar > ul > li {
    display: inline-block;
    padding-right: 1.4rem;
    padding-top: 0;
  }
  nav#navbar > ul > li.has-submenu {
    position: relative;
  }
  nav#navbar > ul > li.has-submenu > a {
    padding-right: 1.5rem;
  }
  nav#navbar > ul > li.has-submenu > a:before, nav#navbar > ul > li.has-submenu > a:after {
    width: 1rem;
    top: 0.7rem;
    background-color: #009ee2;
  }
  nav#navbar > ul > li.has-submenu .submenu {
    position: absolute;
    display: block;
    top: 2.5rem;
    left: -4rem;
    width: 15rem;
    margin: 0;
    padding: 0.6rem;
    background-color: #ffffff;
    opacity: 0;
    transform: translateY(20%);
    transition: all 0.3s linear;
  }
  nav#navbar > ul > li.has-submenu .submenu > li {
    display: block;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease;
  }
  nav#navbar > ul > li.has-submenu .submenu > li:before {
    content: none;
  }
  nav#navbar > ul > li.has-submenu .submenu > li:hover {
    background-color: #009ee2;
  }
  nav#navbar > ul > li.has-submenu.active .submenu {
    opacity: 1;
    transform: translateY(0);
  }
}

/*--------------------------------------------------------*/
/*	6. SECTIONS */
/*--------------------------------------------------------*/
section {
  padding-block: 6rem;
}
section .title {
  position: relative;
  text-align: center;
  margin-bottom: 4.5rem;
}
section .title::before, section .title::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 12rem;
  border-bottom: 0.15rem solid #f5f5f5;
  left: 50%;
  transform: translateX(-50%);
}
section .title::after {
  width: 4rem;
  border-bottom: 0.15rem solid #009ee2;
}
section.hero-slider {
  min-height: 80vh;
  position: relative;
  overflow: hidden;
}
section.hero-slider ul.hero-bg > li {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  transition: 1s ease;
}
section.hero-slider ul.hero-bg > li .hero-txt {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
section.hero-slider .hero-pgs {
  position: absolute;
  bottom: 3rem;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  gap: 1.4rem;
}
section.hero-slider .hero-pgs > li {
  position: relative;
  display: grid;
  place-items: center;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
}
section.hero-slider .hero-pgs > li:after {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  border-radius: 50%;
  border: 0.1rem solid #ffffff;
  transform: scale(0, 0);
  opacity: 0;
  transition: 0.5s;
}
section.hero-slider .hero-pgs > li:hover:after {
  transform: scale(1, 1);
  opacity: 1;
}
section.hero-slider .hero-pgs > li.hero-pgs-active {
  background-color: #009ee2;
}
section.hero-slider .hero-pgs > li.hero-pgs-active:after {
  border-color: #009ee2;
  transform: scale(1, 1);
  opacity: 1;
}
section.page-title {
  position: relative;
  padding-block: 12rem 8rem;
}
@media (min-width: 55em) {
  section {
    padding-block: 8rem;
  }
  section.p-md {
    padding-block: 4.5rem;
  }
  section.p-big {
    padding-block: 12rem;
  }
  section.hero-slider ul.hero-bg > li .hero-txt {
    align-items: start;
  }
  section.hero-slider .hero-pgs {
    top: 50%;
    right: 3rem;
    flex-direction: column;
    transform: none;
  }
}

/*--------------------------------------------------------*/
/*	7. SERVICES AND PARTNERS    */
/*--------------------------------------------------------*/
.services-grid {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
}
.services-grid .service,
.services-grid .service-cta {
  width: 100%;
  padding: 1.4rem;
  margin-bottom: 1.4rem;
}
.services-grid .service svg,
.services-grid .service-cta svg {
  margin-bottom: 1.4rem;
  transition: all 0.2s ease;
}
.services-grid .service svg:hover,
.services-grid .service-cta svg:hover {
  transform: translateY(-0.6rem);
}
.services-grid .service-cta {
  grid-column: span 2;
  flex-direction: column;
  gap: 0.6rem;
  padding: 0 1.8rem 2.5rem 1.8rem;
  margin-bottom: 0;
}
.services-grid .service-cta img {
  width: 6rem;
  aspect-ratio: 1;
  border-radius: 50%;
}
@media (min-width: 55em) {
  .services-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .services-grid .service,
.services-grid .service-cta {
    margin-bottom: 5rem;
    border-right: 0.1rem dashed rgba(255, 255, 255, 0.2);
  }
  .services-grid .service:nth-child(3n),
.services-grid .service-cta:nth-child(3n) {
    border-color: transparent;
  }
  .services-grid .service-cta {
    grid-column: span 1;
    flex-direction: row;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.service-block {
  gap: 0;
}
.service-block > a {
  position: relative;
  display: inline-block;
  line-height: 0;
  overflow: hidden;
}
.service-block > a:hover img {
  transform: scale(1.09, 1.09);
  transition: all 1s ease;
}
.service-block > div {
  display: grid;
  place-content: center;
  place-items: start;
  padding: 2rem;
}
@media (min-width: 55em) {
  .service-block > a img {
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .service-block:nth-child(2n) > a {
    order: 2;
  }
}

.service-thumb > a {
  position: relative;
  display: block;
  line-height: 0;
  overflow: hidden;
}
.service-thumb > a:hover img {
  transform: scale(1.15);
  transition: all 0.4s;
}
.service-thumb .thumb-description {
  padding: 1.4rem;
  margin-inline: 2.5rem;
  transform: translateY(-2.5rem);
  background: #192026;
}

.partners {
  grid-template-columns: repeat(2, 1fr);
}
.partners > * {
  max-width: 6rem;
  line-height: 0;
  opacity: 0.5;
  transition: all 0.8s cubic-bezier(0.18, 0.9, 0.4, 1.3);
}
.partners > *:hover {
  opacity: 1;
}
@media (min-width: 55em) {
  .partners {
    grid-template-columns: repeat(6, 1fr);
  }
}

/*--------------------------------------------------------*/
/*	8. PRICINGS */
/*--------------------------------------------------------*/
.pricing {
  padding: 1.4rem;
  border: 0.1rem dashed rgba(255, 255, 255, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
  border-width: 0.15rem;
}
.pricing img:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}
.pricing ul {
  grid-template-columns: repeat(3, 1fr);
}
.pricing ul .pricing-feature {
  padding: 0.6rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
}
@media (min-width: 55em) {
  .pricing {
    padding: 3rem;
  }
  .pricing .pricing-feature {
    padding: 0.6rem 1.4rem;
  }
}


.big-pricing {
  position: relative;
  overflow: hidden;
  gap: 0;
}
.big-pricing > a {
  display: block;
}
.big-pricing > a .label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 22rem;
  aspect-ratio: 1;
  transform: rotate(-45deg);
}
.big-pricing > a .label span {
  display: block;
  padding: 1rem;
  background-color: #d41d22;
}
.big-pricing > a img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.big-pricing > div {
  display: grid;
  place-content: center;
  place-items: start;
  padding: 2rem;
  background: #192026;
}
.big-pricing > div ul {
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 3rem;
}
.big-pricing > div ul .pricing-feature {
  padding: 0.6rem;
  border: 0.1rem solid rgba(255, 255, 255, 0.2);
}
.big-pricing > div a.btn:hover {
  background: #ffffff;
}
@media (min-width: 55em) {
  .big-pricing > div {
    padding: 4.5rem;
  }
}

/*--------------------------------------------------------*/
/*	10. GALLERY */
/*--------------------------------------------------------*/
.gallery-container {
  display: grid;
  gap: 0.6rem;
  padding: 0;
}
.gallery-container >  img {
  aspect-ratio: 3/2;
  -o-object-fit: cover;
     object-fit: cover;
}
.gallery-container > img:hover {
  filter: brightness(0.5);
  transition: 0.4s cubic-bezier(0.5, 0.05, 0.155, 0.99);
}
@media (min-width: 55em) {
  .gallery-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.lightbox {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.lightbox img {
  max-height: 95vh;
  box-shadow: 0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.2);
}
.lightbox > svg {
  position: absolute;
  font-size: 2rem;
  color: #ffffff;
  cursor: pointer;
}
.lightbox > svg.fa-xmark {
  top: 1rem;
  right: 1rem;
}
.lightbox > svg.fa-arrow-left {
  left: 1rem;
}
.lightbox > svg.fa-arrow-right {
  right: 1rem;
}

.open {
  display: flex;
}

/*--------------------------------------------------------*/
/*	11. ACCORDION */
/*--------------------------------------------------------*/
.accordion {
  position: relative;
  margin-bottom: 0.6rem;
}
.accordion > input[type=checkbox] {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.accordion > input[type=checkbox]:checked ~ .accordion-tab svg {
  padding-top: 0.6rem;
  padding-right: 0;
  transform: rotate(90deg);
}
.accordion > input[type=checkbox]:checked ~ .accordion-tab ~ .accordion-content {
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content;
  padding: 1.4rem;
}
.accordion > input[type=checkbox]:hover ~ .accordion-tab {
  background: #009ee2;
}

.accordion-tab {
  padding: 0.6rem 1.4rem;
  margin-bottom: 0;
  background: #f5f5f5;
  transition: 0.4s ease-in;
}
.accordion-tab svg {
  padding-right: 0.6rem;
  color: #192026;
  transition: 0.4s ease-in;
}

.accordion-content {
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.65s ease;
}

/*--------------------------------------------------------*/
/*	12. BLOG */
/*--------------------------------------------------------*/
.grid-blog {
  display: grid;
  grid-template-columns: 1fr;
  place-items: start;
  gap: 3rem;
}
.grid-blog aside {
  background-color: #f5f5f5;
}
.grid-blog aside > *:not(:last-child) {
  margin-bottom: 3rem;
}
.grid-blog aside h3 {
  position: relative;
  padding-bottom: 0.6rem;
  margin-bottom: 1.4rem !important;
}
.grid-blog aside h3::before, .grid-blog aside h3::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: 0.15rem solid #ffffff;
}
.grid-blog aside h3::after {
  left: 0;
  width: 4rem;
  border-bottom: 0.15rem solid #009ee2;
}

.post-content,
ul.bb {
  border-bottom: 0.1rem dashed rgba(255, 255, 255, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
  border-width: 0.15rem;
  margin-bottom: 0.6rem;
}

.post-content > *:not(:first-child) {
  margin-bottom: 3rem;
}

.tags li > a {
  display: inline-block;
  background: #f5f5f5;
  padding: 0.2rem 0.6rem;
  margin-bottom: 0.2rem;
  transition: 0.4s ease;
}
.tags li > a:hover {
  background: #192026;
}

.sharing-list {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.comment-list,
.nested-comment-list {
  margin-bottom: 4.5rem;
}
.comment-list article,
.nested-comment-list article {
  display: grid;
  grid-template-columns: 5rem 1fr;
  gap: 1.4rem;
  padding-block: 3rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
}
.comment-list article img,
.nested-comment-list article img {
  border-radius: 100%;
}

.nested-comment-list {
  padding-left: 3rem;
}

.post > a {
  position: relative;
  display: block;
  line-height: 0;
  margin-bottom: 1rem;
  overflow: hidden;
}
.post > a:hover img {
  transform: scale(1.15);
  transition: all 0.4s;
}
.post > a .date-img {
  position: absolute;
  display: grid;
  place-items: center;
  bottom: 0;
  left: 0;
  aspect-ratio: 1;
  padding: 0.6rem;
  padding-top: 1rem;
  background-color: #009ee2;
}
.post ul {
  margin-bottom: 0.3rem;
}
.post ul li {
  padding-right: 0;
}
.post ul li:not(:last-child)::after {
  content: "|";
  padding-inline: 0.3rem;
}
.post h3 {
  margin-bottom: 0.3rem;
}

@media (min-width: 55em) {
  .grid-blog {
    grid-template-columns: 2.8fr 1fr;
  }
  .nested-comment-list {
    padding-left: 6rem;
  }
  .sharing {
    position: relative;
  }
  .sharing .sharing-list {
    position: absolute;
    right: 0;
    bottom: -1rem;
    transform: rotateX(90deg);
    transition: 0.4s ease-in;
  }
  .sharing:hover .sharing-list {
    transform: rotateX(0);
  }
}
/*--------------------------------------------------------*/
/*	13. FOOTER */
/*--------------------------------------------------------*/
footer {
  background: #192026;
  background-repeat: no-repeat;
  background-position: center;
}
footer .subscribe-cta {
  padding-top: 4.5rem;
  text-align: left;
}
footer .subscribe-cta form {
  display: grid;
  grid-template-columns: 1fr;
  place-content: center;
}
footer .subscribe-cta form input {
  padding-left: 1.4rem;
  margin-bottom: 1rem;
}
footer img {
  width: 10rem;
}
footer ul li {
  width: 100%;
  padding: 0.6rem;
}
footer ul li svg {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}
footer ul li p {
  margin-bottom: 0 !important;
}
footer .copyright {
  margin-top: 0.6rem;
  padding-block: 1.4rem;
  background: rgba(0, 0, 0, 0.2);
}
@media (min-width: 55em) {
  footer .subscribe-cta form {
    grid-template-columns: 1fr 0.6fr;
  }
  footer .subscribe-cta form input {
    margin-bottom: 0;
  }
  footer ul li:not(:last-child) {
    border-right: 0.1rem dashed rgba(255, 255, 255, 0.2);
  }
}

/*--------------------------------------------------------*/
/*	14. ANIMATIONS */
/*--------------------------------------------------------*/
@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes zoom {
  from {
    background-size: 105%;
  }
  to {
    background-size: 125%;
  }
}
@keyframes zoom {
  from {
    background-size: 105%;
  }
  to {
    background-size: 125%;
  }
}